export function rejectNonDigits(e){
	if((e.charCode < 48 || e.charCode > 57)
		&& e.charCode !== 13 && e.charCode !== 0 // "13" here is for submit on Enter, 0 for backspace, delete and arrows (FF only)
		&& !((e.charCode == 118 || e.charCode == 120 || e.charCode == 99) && e.ctrlKey)){ // Add exceptions for Ctrl+(C,V,X) in FF

		e.preventDefault()
	}
}

export function cleanNonDigits(e){
	e.preventDefault()

	var pastedText = ''
	if (e.clipboardData && e.clipboardData.getData) {
		pastedText = e.clipboardData.getData('text/plain')
	}

	e.target.value = pastedText.replace(/\D/g, '')
	e.target.dispatchEvent(new Event('blur')) // This is a hack to call validator
}

let Forms = {
	rejectNonDigits,
	cleanNonDigits
}

export default Forms